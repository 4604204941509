import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyListReviews = lazy(
  () => import("./list-reviews-Ta-h4x9Q.js").then((module) => ({
    default: module.ListReviews
  }))
);
function ListReviews({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyListReviews, { ...props });
}
const useListReviewsContent = () => {
  return useMemo(
    () => ({
      listReviews: {
        component: ListReviews
      }
    }),
    []
  );
};
export {
  LazyListReviews as ListReviews,
  useListReviewsContent
};
